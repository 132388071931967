.queue-modal {
  position: fixed;
  bottom: 120px;
  padding: 1rem;
  max-height: 60vh;
  height: 60vh;
  width: 220px;
  background-color: #ffffffd9;
  z-index: 9999;

}

.queue-detail > div {
  display:  flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.25rem;

}

.queue-detail > div > div {
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close-btn {
  /* margin: 0.25rem; */
  cursor: pointer;
  user-select: none;

}
